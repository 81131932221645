/*
 * App Routes
 *
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";

// app default layout
import HulkAppLayout from "components/HulkAppLayout";

// router service
import routerService from "../routes/Routes";

function DefaultLayout(props) {
  const { match } = props;
  return (
    <HulkAppLayout>
      {routerService.map((route, key) => (
        <Route
          key={key}
          path={`${match.url}/${route.path}`}
          // Permission based redirecting to component (code modified).
          render={(props) =>
            route.meta && route.meta.permission === false ? (
              <Redirect
                to={{
                  pathname: "/error/403",
                  state: { from: props.location },
                }}
              />
            ) : (
              <route.component {...props} />
            )
          }
        />
      ))}
    </HulkAppLayout>
  );
}

export default DefaultLayout;
