const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const MenuItems = [
  {
    menu_title: "Dashboard",
    path: "/app/dashboard",
    icon: "dashboard",
    category: "general",
    isMenuOpen: false,
    menu: "dashboard",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    permission: true,
  },

  {
    menu_title: "Master",
    path: "/app/master/",
    icon: "folder",
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Master !== undefined ? permission.Master.length > 0 : false,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User Master",
        icon: "person",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("user")
            : false,
      },
      // {
      //   path: "/app/master/formInformation",
      //   menu_title: "Form-Information",
      //   icon: "add_box",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: false
      // },
      {
        path: "/app/master/designation",
        menu_title: "Designation Master",
        icon: "work_outline",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("designation_master")
            : false,
      },
      {
        path: "/app/master/department",
        menu_title: "Department Master",
        icon: "business",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("department_master")
            : false,
      },
      {
        path: "/app/master/city",
        menu_title: "City Master",
        icon: "location_city",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("city_master")
            : false,
      },
      {
        path: "/app/master/branch",
        menu_title: "Branch Master",
        icon: "add_business",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("branch_master")
            : false,
      },
      {
        path: "/app/master/suburb",
        menu_title: "Suburb Master",
        icon: "landscape",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("suburb_master")
            : false,
      },
      {
        path: "/app/master/product",
        menu_title: "Product Master",
        icon: "local_grocery_store",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("product_master")
            : false,
      },
      {
        path: "/app/master/model",
        menu_title: "Model Master",
        icon: "two_wheeler",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("model_master")
            : false,
      },
      {
        path: "/app/master/colour",
        menu_title: "Colour Master",
        icon: "color_lens",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("color_master")
            : false,
      },
      {
        path: "/app/master/leadsource",
        menu_title: "Lead Source Master",
        icon: "grade",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("lead_source_master")
            : false,
      },
      {
        path: "/app/master/leadtype",
        menu_title: "Lead Type Master",
        icon: "auto_awesome",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("lead_type")
            : false,
      },
      {
        path: "/app/master/remark",
        menu_title: "Remark Master",
        icon: "follow_the_signs",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("remark_master")
            : false,
      },
      {
        path: "/app/master/whatsappdraft",
        menu_title: "WhatsApp Draft Master",
        icon: "chat",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("wa_draft_master")
            : false,
      },
      {
        path: "/app/master/whatsapp-draft-category",
        menu_title: "WhatsApp Draft Category Master",
        icon: "category",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("wa_draft_category_master")
            : false,
      },
      {
        path: "/app/master/templatetrigger",
        menu_title: "Template Trigger Master",
        icon: "send_time_extension",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("template_trigger_master")
            : false,
      },
      {
        path: "/app/master/insurance",
        menu_title: "Insurance Master",
        icon: "health_and_safety",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("insurance_master")
            : false,
      },
      {
        path: "/app/master/insurancecompany",
        menu_title: "Insurance Company Master",
        icon: "medical_information",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("insurance_company_master")
            : false,
      },

    ],
  },
  {
    menu_title: "Leads",
    path: "/app/leads",
    icon: "group_add",
    category: "components",
    isMenuOpen: false,
    menu: "leads",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Leads !== undefined ? permission.Leads.length > 0 : false,
    child_routes: [
      {
        path: "/app/leads/leadsform",
        menu_title: "Leads Form",
        icon: "manage_accounts",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Leads !== undefined
            ? permission && permission.Leads.includes("leads_form")
            : false,
      },
      {
        path: "/app/leads/leads-import",
        menu_title: "Leads Import",
        icon: "download_for_offline",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Leads !== undefined
            ? permission && permission.Leads.includes("leads_import")
            : false,
        // permission: true,
      },
      // {
      //   path: "/app/leads/customers-import",
      //   menu_title: "Customers Import",
      //   icon: "download_for_offline",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   // permission:
      //   //   permission && permission.Leads !== undefined
      //   //     ? permission && permission.Leads.includes("customers_import")
      //   //     : false,
      //   permission: true,
      // },
    ],
  },
  {
    menu_title: "Reports",
    path: "/app/crm-reports",
    icon: "assignment",
    category: "CRM_Reports",
    isMenuOpen: false,
    menu: "CRM_Reports",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.CRM_Reports !== undefined ? permission.CRM_Reports.length > 0 : false,
    child_routes: [
      // {
      //   path: "/app/reports/products-report",
      //   menu_title: "Products Report",
      //   icon: "assignment_ind",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission: (permission && permission.Reports !== undefined ? permission && permission.Reports.includes("leads_form") : false),
      // },
      {
        path: "/app/crm-reports/call-summary-report",
        menu_title: "Call Summary Report",
        icon: "contact_phone",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.CRM_Reports !== undefined
            ? permission && permission.CRM_Reports.includes("call_summary_report")
            : false,
      },
      {
        path: "/app/crm-reports/user-call-log-report",
        menu_title: "User Call Log Report",
        icon: "perm_phone_msg",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.CRM_Reports !== undefined
            ? permission && permission.CRM_Reports.includes("user_call_log_report")
            : false,
      },
      {
        path: "/app/crm-reports/calling-history-report",
        menu_title: "Calling History Report",
        icon: "history",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.CRM_Reports !== undefined
            ? permission &&
            permission.CRM_Reports.includes("calling_history_report")
            : false,
      },
      // {
      //   path: "/app/reports/sales-report",
      //   menu_title: "Sales Report",
      //   icon: "assignment_ind",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.Reports !== undefined
      //       ? permission && permission.Reports.includes("sales_report")
      //       : false,
      // },
      {
        path: "/app/crm-reports/leads-report",
        menu_title: "Leads Report",
        icon: "assignment_ind",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.CRM_Reports !== undefined
            ? permission && permission.CRM_Reports.includes("leads_report")
            : false,
      },
      {
        path: "/app/crm-reports/test-ride-report",
        menu_title: "Test Ride Report",
        icon: "two_wheeler",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.CRM_Reports !== undefined
            ? permission && permission.CRM_Reports.includes("test_ride_report")
            : false,
      },
    ],
  },

  {
    menu_title: "Service Reports",
    path: "/app/serviceReports",
    icon: "two_wheeler",
    category: "serviceReports",
    isMenuOpen: false,
    menu: "serviceReports",
    fullPageMenu: false,
    desc: true,
    content: "",
    // permission: true,
    permission: permission?.serviceReports !== undefined ? permission.serviceReports.length > 0 : false,
    child_routes: [
      {
        path: "/app/serviceReports/customer-service-report",
        menu_title: "Customer Service Report",
        icon: "build",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.serviceReports !== undefined
            ? permission &&
            permission.serviceReports.includes("customer_service_report")
            : false,
      },
      {
        path: "/app/serviceReports/service-summary-report",
        menu_title: "Service Summary Report ",
        icon: "tire_repair",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true
        permission:
          permission && permission.serviceReports !== undefined
            ? permission &&
            permission.serviceReports.includes("service_summary_report")
            : false,
      },

    ],
  },
  // ----------------------------Attendance------------------
  {
    menu_title: "Attendance",
    path: "/app/attendance",
    icon: "assignment",
    category: "attendance",
    isMenuOpen: false,
    menu: "attendance",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: 
    permission?.Attendance !== undefined ? permission?.Attendance.length > 0 : false,
    child_routes: [

      {
        path: "/app/attendance/leave-approval",
        menu_title: "Leave Approval",
        icon: "rule",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("leave_approval")
          : false,
      },

      {
        path: "/app/attendance/weekly-off-roster",
        menu_title: "Week Off Roster",
        icon: "history_toggle_off",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("weekly_off_roster")
          : false,
      },

      {
        path: "/app/attendance/attendance-report",
        menu_title: "Daily Report",
        icon: "article",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("daily_report")
          : false,
      },
      {
        path: "/app/attendance/monthly-attendance-report",
        menu_title: "Monthly Report",
        icon: "assessment",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("monthly_report")
          : false,
      },
      {
        path: "/app/attendance/attendance-summary-report",
        menu_title: "Summary Report",
        icon: "summarize",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("summery_report")
          : false,
      },
      {
        path: "/app/attendance/shift",
        menu_title: "Shift Master",
        icon: "work_history",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("shift")
          : false,
      },
      {
        path: "/app/attendance/weekly-off",
        menu_title: "Weekly Off Master",
        icon: "date_range",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("weekly_off")
          : false,
      },
      {
        path: "/app/attendance/holiday",
        menu_title: "Holiday",
        icon: "beach_access",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        // permission: true,
        permission: permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("holiday")
          : false,
      },

    ],
  },
  //------------------------Setting-------------------------//
  {
    menu_title: "HR Setting",
    path: "/app/hr-setting",
    icon: "assignment",
    category: "setting",
    isMenuOpen: false,
    menu: "setting",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    permission: true


    // permission?.Setting !== undefined ? permission?.Setting.length > 0 : false,
    // [
    // {
    //   path: "/app/setting/setting",
    //   menu_title: "Setting Master",
    //   icon: "phonelink_setup",
    //   fullPageMenu: false,
    //   isMenuOpen: false,
    //   third_child_routes: null,
    //   permission:
    //     permission && permission.Setting !== undefined
    //       ? permission && permission.Setting.includes("setting_master")
    //       : false,
    // },
    // {
    //   path: "/app/setting/hr-setting",
    //   menu_title: "HR Setting Master",
    //   icon: "manage_accounts",
    //   fullPageMenu: false,
    //   isMenuOpen: false,
    //   third_child_routes: null,
    //   permission:
    //     permission && permission.Setting !== undefined
    //       ? permission && permission.Setting.includes("hr_setting")
    //       : false,
    // },


    // ],

  },

];

export default MenuItems;
