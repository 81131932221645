/**
 * Horizontal Menu
 */
import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";

import MenuItems from "routes/MenuItems";

import NavMenuItem from "./NavMenuItem";
import Scrollbars from "react-custom-scrollbars";

function HorizontalMenu() {
  const [navLinks] = useState(MenuItems);
  const [general, setGeneral] = useState(null);
  const [modules, setModules] = useState(null);
  const [component, setComponents] = useState(null);
  const [report, setReports] = useState(null);
  const [serviceReports, setServiceReports] = useState(null);
  const [attendance, setAttendance] = useState(null);
  const [setting, setSetting] = useState(null);

  useEffect(() => {
    setCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCategory = () => {
    let category1 = navLinks.filter((item, i) => {
      return item.category === "general" && item.permission === true;
    });
    let category2 = navLinks.filter((item, i) => {
      return item.category === "modules" && item.permission === true;
    });
    let category3 = navLinks.filter((item, i) => {
      return item.category === "components" && item.permission === true;
    });
    let category4 = navLinks.filter((item, i) => {
      return item.category === "CRM_Reports" && item.permission === true;
    });
    let category5 = navLinks.filter((item, i) => {
      return item.category === "serviceReports" && item.permission === true;
    });
    let category6 = navLinks.filter((item, i) => {
      return item.category === "attendance" && item.permission === true;
    });
    let category7 = navLinks.filter((item, i) => {
      return item.category === "setting" && item.permission === true
    });
    setGeneral(category1);
    setModules(category2);
    setComponents(category3);
    setReports(category4);
    setServiceReports(category5);
    setAttendance(category6);
    setSetting(category7);

  };

  return (
    <Box className="horizontal-menu">
      <ul className="list-unstyled nav">
        {general &&
          general.map((menu, key) => (
            <li className="menu-item">
              <Link
                to="/app/dashboard"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  fontSize: "15px",
                  color: "black",
                }}
              >
                <i
                  className="material-icons-outlined"
                  style={{ fontSize: "18px", marginRight: "8px" }}
                >
                  dashboard
                </i>
                <span>Dashboard</span>
              </Link>
            </li>
          ))}

        {modules &&
          modules.map((menu, key) => {
            //In this i am filtering Master's child_routes permission:true for showing menu item.
            let childRoutes = null;
            if (menu.child_routes && menu.child_routes.length > 0) {
              childRoutes = menu.child_routes.filter(
                (child) => child.permission === true
              );
            }
            return (
              <li className="nav-item menu-item-has-child">
                <span>
                  <i className="material-icons-outlined">widgets</i>
                  <span className="menu-title">Master</span>
                </span>
                <ul className="list-unstyled sub-menu">
                  <Scrollbars
                    className="rct-scroll"
                    autoHeight
                    autoHeightMin={"70vh"}
                  >
                    <NavMenuItem
                      menu={{
                        ...menu,
                        child_routes: childRoutes,
                      }}
                      key={key}
                    />
                  </Scrollbars>
                </ul>
              </li>
            );
          })}

        {component &&
          component.map((menu, key) => {
            //In this i am filtering Master's child_routes permission:true for showing menu item.
            let childRoutes = null;
            if (menu.child_routes && menu.child_routes.length > 0) {
              childRoutes = menu.child_routes.filter(
                (child) => child.permission === true
              );
            }

            return (
              <li className="nav-item menu-item-has-child">
                <span>
                  <i className="material-icons-outlined">group_add</i>
                  <span className="menu-title">Manage Leads</span>
                </span>
                <ul className="list-unstyled sub-menu">
                  <NavMenuItem
                    menu={{
                      ...menu,
                      child_routes: childRoutes,
                    }}
                    key={key}
                  />
                </ul>
              </li>
            );
          })}

        {report &&
          report.map((menu, key) => {
            //In this i am filtering Master's child_routes permission:true for showing menu item.
            let childRoutes = null
            if (menu.child_routes && menu.child_routes.length > 0) {
              childRoutes = menu.child_routes.filter(
                (child) => child.permission === true
              )
            }
            return (
              <li className="nav-item menu-item-has-child">
                <span>
                  <i className="material-icons-outlined">assignment</i>
                  <span className="menu-title">CRM Reports</span>
                </span>
                <ul className="list-unstyled sub-menu">
                  <NavMenuItem
                    menu={{
                      ...menu,
                      child_routes: childRoutes,
                    }} key={key} />
                </ul>
              </li>
            )
          })}

        {serviceReports &&
          serviceReports.map((menu, key) => {
            //In this i am filtering Master's child_routes permission:true for showing menu item.
            let childRoutes = null;
            if (menu.child_routes && menu.child_routes.length > 0) {
              childRoutes = menu.child_routes.filter(
                (child) => child.permission === true
              );
            }

            return (
              <li className="nav-item menu-item-has-child">
                <span>
                  <i className="material-icons-outlined">assignment</i>
                  <span className="menu-title">Service Reports</span>
                </span>
                <ul className="list-unstyled sub-menu">
                  <NavMenuItem
                    menu={{
                      ...menu,
                      child_routes: childRoutes,
                    }}
                    key={key}
                  />
                </ul>
              </li>
            );
          })}

        {attendance &&
          attendance.map((menu, key) => {
            //In this i am filtering Master's child_routes permission:true for showing menu item.
            let childRoutes = null
            if (menu.child_routes && menu.child_routes.length > 0) {
              childRoutes = menu.child_routes.filter(
                (child) => child.permission === true
              )
            }
            return (
              <li className="nav-item menu-item-has-child">
                <span>
                  <i className="material-icons-outlined">assignment_ind</i>
                  <span className="menu-title">attendance</span>
                </span>
                <ul className="list-unstyled sub-menu">
                  <NavMenuItem
                    menu={{
                      ...menu,
                      child_routes: childRoutes,
                    }} key={key} />
                </ul>
              </li>
            )
          })}

        {/* {setting &&
          setting.map((menu, key) => {
            //In this i am filtering Master's child_routes permission:true for showing menu item.
            let childRoutes = null
            if (menu.child_routes && menu.child_routes.length > 0) {
              childRoutes = menu.child_routes.filter(
                (child) => child.permission === true
              )
            }
            return (
              <li className="nav-item menu-item-has-child">
                <span>
                  <i className="material-icons-outlined">settings</i>
                  <span className="menu-title">Setting</span>
                </span>
                <ul className="list-unstyled sub-menu">
                  <NavMenuItem
                    menu={{
                      ...menu,
                      child_routes: childRoutes,
                    }} key={key} />
                </ul>
              </li>
            )
          })} */}
        {setting &&
          setting.map((menu, key) => (
            <li className="menu-item">
              <Link
                to="/app/hr-setting"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  fontSize: "12px",
                  color: "black",
                }}
              >
                <i
                  className="material-icons-outlined"
                  style={{ fontSize: "18px", marginRight: "8px" }}
                >
                  settings
                </i>
                <span>HR Setting</span>
              </Link>
            </li>
          ))}
      </ul>
    </Box>
  );
}

export default HorizontalMenu;
