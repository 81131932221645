

import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Box, InputAdornment, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CreatePassword } from 'Redux/actions/auth/index'



function SetNewPassword(props) {
  const {
    handleSubmit,
    watch,
    formState: { errors },
    register,
    reset
  } = useForm();

  const password = useRef({})
  password.current = watch("password", "")

  const [values, setValues] = useState({
    currentPassword: '',
    newPassword: '',
    retypePassword: '',
    showPassword: false,
    showPasswordConfirm: false,
    formSubmitted: false,
    passwordsMatch: false,
  });



  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword },);

  };

  const handleClickShowPasswordConfirm = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm })
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);


  // main render function

  const { isDarkModeActive } = settings;

  const onSubmit = async (data, e) => {
    dispatch(CreatePassword(data, props))
    reset();
  }



  return (
    <div>
      <div className="session-wrapper">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={4} className="login-wrap">
            <div className="login-wrapper text-center" >
              <div className="w-100">
                <div className="session-logo">
                  {isDarkModeActive ?
                    <img className="img-fluid" alt="img" width="100" src={require(`assets/Images/hulk-light.png`).default} />
                    :
                    <img className="img-fluid" alt="img" width="100" src={require(`assets/Images/hulk-dark.png`).default} />
                  }
                </div>
                <form onSubmit={handleSubmit((data, e) => onSubmit(data, e))}>
                  <Box mb={3}>
                    <TextField
                      // required
                      fullWidth
                      id="password"
                      name="password"
                      label="New password"
                      placeholder="Please enter your new password."
                      className={errors.password ? "is-invalid" : ""}
                      type={values.showPassword ? 'text' : 'password'}
                      {...register("password", {
                        required: "Please enter new password",
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && (
                      <Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>
                        {errors.password.message}
                      </Box>
                    )}
                  </Box>
                  <Box mb={3}>
                    <TextField
                      // required
                      fullWidth
                      id="password_repeat"
                      label="Confirm password"
                      className={errors.password_repeat ? "is-invalid" : ""}
                      type={values.showPasswordConfirm ? 'text' : 'password'}
                      defaultValues=""

                      {...register("password_repeat", {
                        required: "Please enter confirm password",
                        validate: value => value === password.current || "The passwords does not match"
                      })}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPasswordConfirm}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password_repeat && (
                      <Box component="span" color="error.main" textAlign="left" display="block" fontSize="subtitle2.fontSize" pt={1}>
                        {errors.password_repeat.message}
                      </Box>
                    )}

                  </Box>
                  <Box mb="40px" pt="20px">
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8} style={{ backgroundImage: "url(" + require('assets/Images/session1bg.jpg').default + ")", backgroundSize: 'cover', backgroundPosition: 'center right' }} className="img-session">
            <div className="login-content">
              <Box fontSize="h1.fontSize" fontWeight="h1.fontWeight" mb={4} color="common.white">
                Benefit Yourself With Our 100+ Designs
              </Box>

            </div>
          </Grid>
        </Grid>
      </div>
    </div >
  );
}


export default SetNewPassword;
