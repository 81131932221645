import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import ContactReducer from "./ContactReducer";
import AppSettingsReducer from "./AppSettingsReducer";
import UserSettingsReducer from "./UserSettingsReducer";
import MenuListReducer from "./MenuListReducer";
import AuthReducer from "./auth";
import FormInfoReducer from "./FormInfoReducer";
import UserMasterReducer from "./master/UserMasterReducer";
import DesignationMasterReducer from "./master/DesignationMasterReducer";
import DepartmentMasterReducer from "./master/DepartmentMasterReducer";
import CityMasterReducer from "./master/CityMasterReducer";
import BranchMasterReducer from "./master/BranchMasterReducer";
import WaDraftMasterReducer from "./master/WaDraftMasterReducer";
import RemarkhMasterReducer from "./master/RemarkMasterReducer";
import LeadSourceReducer from "./master/LeadSourceMasterReducer";
import LeadFormReducer from "./master/LeadFormReducer";
import SuburbMasterReducer from "./master/SuburbMasterReducer";
import TemplateTriggerMasterReducer from "./master/TemplateTriggerMasterReducer";
import ColorMasterReducer from "./master/ColorMasterReducer";
import ModelMasterReducer from "./master/ModelMasterReducer";
import ProductMasterReducer from "./master/ProductMasterReducer";
import SettingMasterReducer from "./master/SettingMasterReducer";
import InsuranceCompanyMasterReducer from "./master/InsuranceCompanyMasterReducer";
import InsuranceMasterReducer from "./master/InsuranceMasterReducer";
import LeadsImportReducer from "./master/LeadsImportReducer";
import SalesReportReducer from "./reports/SalesReportReducer";
import CallingHistoryReportReducer from "./reports/CallingHistoryReportReducer";
import DashboardReducer from "./dashboard/DashboardReducer";
import CallingPerformanceReducer from "./reports/CallingPerformanceReportReducer";
import LeadsReportReducer from "./reports/LeadsReportReducer";
import WaDraftCategoryReducer from "./master/WaDraftCategoryMasterReducer";
import CustomerServiceReportReducer from "./serviceReports/CustomerServiceReport";
import ServiceSummaryReportReducer from "./serviceReports/ServiceSummaryReport";
import shiftReducer from "./attendance/ShiftMasterReducer";
import weeklyOffReducer from "./attendance/WeeklyOffMasterReducer";
import holidayReducer from "./attendance/HolidayMasterReducer";
import AttendanceReportReducer from "./attendance/AttendanceReportReducer";
import MonthlyAttendanceReportReducer from "./attendance/MonthlyAttendanceReport";
import testRideReportReducer from "./reports/TestRideReport"
import usercalllogreporttReducer from "./reports/UserCallLogReducer"
import leadTypeReducer from "./master/LeadTypeMasterReducer"
import AttendanceSummaryReportReducer from "./attendance/AttendanceSummaryReportReducer"
import hrSettingReducer from "./master/HrSettingMasterReducer"
import weeklyOffRosterReducer from "./attendance/WeeklyOffRosterReducer"
import approvalMasterReducer from "./attendance/ApprovalMasterReducer"


const reducers = combineReducers({
  authUser: AuthUserReducer,
  settings: AppSettingsReducer,
  ContactReducer: ContactReducer,
  UserSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  authReducer: AuthReducer,
  formInfoReducer: FormInfoReducer,
  userMasterReducer: UserMasterReducer,
  designationMasterReducer: DesignationMasterReducer,
  departmentMasterReducer: DepartmentMasterReducer,
  cityMasterReducer: CityMasterReducer,
  branchMasterReducer: BranchMasterReducer,
  waDraftMasterReducer: WaDraftMasterReducer,
  remarkMasterReducer: RemarkhMasterReducer,
  leadSourceReducer: LeadSourceReducer,
  leadFormReducer: LeadFormReducer,
  suburbMasterReducer: SuburbMasterReducer,
  templateTriggerMasterReducer: TemplateTriggerMasterReducer,
  colorMasterReducer: ColorMasterReducer,
  modelMasterReducer: ModelMasterReducer,
  productMasterReducer: ProductMasterReducer,
  settingMasterReducer: SettingMasterReducer,
  insuranceCompanyMasterReducer: InsuranceCompanyMasterReducer,
  insuranceMasterReducer: InsuranceMasterReducer,
  leadsImportReducer: LeadsImportReducer,
  salesReportReducer: SalesReportReducer,
  callingHistoryReportReducer: CallingHistoryReportReducer,
  dashboardReducer: DashboardReducer,
  callingPerformanceReducer: CallingPerformanceReducer,
  leadsReportReducer: LeadsReportReducer,
  waDraftCategoryReducer: WaDraftCategoryReducer,
  customerServiceReportReducer: CustomerServiceReportReducer,
  serviceSummaryReportReducer: ServiceSummaryReportReducer,
  shiftMasterReducer: shiftReducer,
  weeklyOffReducer: weeklyOffReducer,
  HolidayReducer: holidayReducer,
  attendanceReportReducer: AttendanceReportReducer,
  monthlyAttendanceReportReducer: MonthlyAttendanceReportReducer,
  testRideReportReducer: testRideReportReducer,
  userCalllogreporttReducer: usercalllogreporttReducer,
  leadTypeReducer: leadTypeReducer,
  attendanceSummaryReportReducer: AttendanceSummaryReportReducer,
  hrSettingReducer: hrSettingReducer,
  weeklyOffRosterReducer: weeklyOffRosterReducer,
  approvalMasterReducer: approvalMasterReducer,

});

export default reducers;
