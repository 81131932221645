const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
}
const usercalllogreporttReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_DATA_USER_CALLLOG_REPORT":
            return {
                ...state,
                allData: action.allData,
                data: action.data.docs,
                total: action.totalPages,
                params: action.params,
            }
        default:
            return { ...state }
    }
}
export default usercalllogreporttReducer
