const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
  };
  
  const ServiceSummaryReportReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_SERVICE_SUMMARY_REPORT":
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
  
      default:
        return { ...state };
    }
  };
  export default ServiceSummaryReportReducer;
  