import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Box, InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import { handleLogin, checkMobileNumberExist } from "Redux/actions/auth/index";

function Login(props) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [phone, setPhone] = useState("");
  const [isPasswordExist, setIsPasswordExist] = useState(false);
  // to hide show Password
  const [showPassword, setShowPassword] = useState(false);
  const store = useSelector((state) => state.authReducer);

  const memoizedStore = useMemo(() => {
    return (
      store || {
        isPasswordExist,
      }
    );
  }, [store, isPasswordExist]);

  useEffect(() => {
    if (memoizedStore && memoizedStore.isPasswordExist !== undefined) {
      setIsPasswordExist(memoizedStore.isPasswordExist);
    } else {
      setIsPasswordExist(false);
    }
  }, [dispatch, memoizedStore]);

  const handleKeyPress = (event) => {
    const pattern = /[0-9]/; // Only allow numeric characters
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  };

  const settings = useSelector((state) => state.settings);

  /**
   * Function to login user using Firebase
   */
  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      data.mobile_number = "+91" + data.mobile_number;
      if (isPasswordExist) {
        dispatch(handleLogin(data, props, phone));
      } else {
        dispatch(checkMobileNumberExist(data, props));
      }
    } catch (error) {
      // Handle API call errors if needed
      console.error("Error while processing form submission:", error);
    }
  };

  /**
   * On User Sign Up
   */

  const onForgotPassword = () => {
    props.history.push("/forgot-password2");
  };

  const { isDarkModeActive } = settings;
  return (
    <div>
      <div className="session-wrapper">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={4} className="login-wrap">
            <div className="login-wrapper text-center">
              <div className="w-100">
                <div className="session-logo">
                  {isDarkModeActive ? (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="100"
                      src={require(`assets/Images/hulk-light.png`).default}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="100"
                      src={require(`assets/Images/hulk-dark.png`).default}
                    />
                  )}
                </div>
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={3}>
                    <Controller
                      name="mobile_number"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Mobile number is required.",
                        minLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits.",
                        },
                      }}
                      render={({ field, onChange, onBlur, value }) => (
                        <TextField
                          fullWidth
                          id="mobile_number"
                          type="tel"
                          defaultCountry="IN"
                          value={value}
                          label="Mobile Number"
                          {...register("mobile_number", {
                            required: "Mobile number is required.",
                            pattern: {
                              value: /^(\+?91|0)?[6789]\d{9}$/, // Add the pattern rule using the Indian mobile number regex
                              message:
                                "Please enter a valid indian mobile number",
                            },
                            validate: (value) =>
                              !!value.trim() ||
                              "Mobile number cannot be empty.",
                          })}
                          placeholder="Please enter your 10-digit mobile number."
                          className={errors.mobile_number ? "is-invalid" : ""}
                          inputProps={{ maxLength: 10 }} // Limit input to 10 characters
                          onKeyPress={handleKeyPress} // Handle key press to disallow non-numeric characters
                          onChange={(phone) => {
                            onChange(phone);
                            setPhone(phone);
                            setIsPasswordExist(false);
                          }}
                          onBlur={onBlur}
                          {...field}
                        />
                      )}
                    />
                    {errors.mobile_number && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        {errors.mobile_number.message}
                      </Box>
                    )}
                  </Box>

                  <Box mb={3}>
                    {isPasswordExist === true && (
                      <TextField
                        fullWidth
                        id="login-password"
                        label="Password"
                        placeholder="Please enter your login password."
                        className={errors.password ? "is-invalid" : ""}
                        name="password"
                        // type="password"
                        type={showPassword ? "text" : "password"} // Toggle password visibility
                        {...register("password", {
                          required: "Password is required.",
                        })}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    {errors.password && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        {errors.password.message}
                      </Box>
                    )}
                  </Box>
                  <Box pt="10px">
                    <div id="recaptcha-container"></div>
                  </Box>
                  <Box mb="40px" pt="20px">
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      Sign In
                    </Button>
                  </Box>
                  <Box fontSize="subtitle2.fontSize">
                    <Box
                      style={{ cursor: "pointer" }}
                      color="primary.main"
                      onClick={() => onForgotPassword()}
                    >
                      Forgot password?
                    </Box>
                  </Box>
                </form>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            style={{
              backgroundImage:
                "url(" + require("assets/Images/loginbg.jpg").default + ")",
              backgroundSize: "cover",
              backgroundPosition: "center right",
            }}
            className="img-session"
          >
            <div className="login-content">
              <Box
                fontSize="h1.fontSize"
                fontWeight="h1.fontWeight"
                mb={4}
                color="common.white"
              >
                INDIA'S FIRST AI ENABLED MOTORCYCLE
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Login;
