const initialState = {
  data: {},
  params: {},
  allData: [],
  total: 1,
  callLogsData: {},
  callLogsparams: {},
  callLogsAllData: [],
  callLogsTotal: 1,
};

const callingPerformanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_CALLING_PERFORMANCE_REPORT":
      return {
        ...state,
        data: action.data,
        params: action.params,
      };

    case "GET_DATA_CALL_LOGS_HISTORY_REPORT":
      return {
        ...state,
        callLogsData: action.data,
        callLogsparams: action.params,
        callLogsAllData: action.allData,
        callLogsTotal: action.totalPages,
      };

    default:
      return { ...state };
  }
};
export default callingPerformanceReducer;
