const initialState = {
    data: {},
    params: {},
    allData: [],
    total: 1,

    viewData: [],
    viewAllData: [],
    viewTotal: 1,

}

const testRideReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TEST_RIDE_REPORT":
            return {
                ...state,
                data: action.data.docs,
                params: action.params,
            }

        case "GET_TEST_RIDE_REPORT_VIEW":
            return {
                ...state,
                viewData: action.data,
                params: action.params,
                viewAllData: action.allData,
                viewTotal: action.totalPages,
            }

        default:
            return { ...state }
    }
}
export default testRideReportReducer
