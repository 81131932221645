/**
 * Router service file
 */

// Async component
import {
  AsyncBranchMasterComponent,
  AsyncCityMasterComponent,
  AsyncColourMasterComponent,
  AsyncDashboardComponent,
  AsyncDepartmentMasterComponent,
  AsyncDesignationMasterComponent,
  AsyncInsuranceCompanyMasterComponent,
  AsyncInsuranceMasterComponent,
  AsyncLeadSourceMasterComponent,
  AsyncLeadsFormMasterComponent,
  AsyncModelMasterComponent,
  AsyncProductMasterComponent,
  AsyncRemarkMasterComponent,
  // AsyncSettingMasterComponent,
  AsyncTemplateTriggerMasterComponent,
  AsyncSuburbMasterComponent,
  AsyncUserMasterComponent,
  AsyncWaDraftMasterComponent,
  AsyncLeadsReportsComponent,
  AsyncLeadsImportComponent,
  AsyncCallingPerformanceReportComponent,
  AsyncCallingHistoryReportComponent,
  AsyncWaDraftCategoryMasterComponent,
  AsyncCustomerServiceReportComponent,
  AsyncServiceSummaryReportComponent,
  AsyncShiftMasterComponent,
  AsyncWeeklyOffMasterComponent,
  AsyncHolidayMasterComponent,
  AsyncAttendanceReportMasterComponent,
  AsyncMonthylyAttendanceReportMasterComponent,
  AsyncTestRideReportsComponent,
  AsyncUserCallLogReportComponent,
  AsyncLeadTypeMasterComponent,
  AsyncAttendanceSummaryReportComponent,
  AsyncHrSettingMasterComponent,
  AsyncWeekOffRosterMasterComponent,
  AsyncLeaveApprovalMasterComponent,

  // AsyncCustomersImportComponent,
} from "routes/ComponentLoader"

const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {}

const routes = [
  //Dashboard's routes & permissions.
  {
    path: "dashboard",
    component: AsyncDashboardComponent,
    meta: {
      permission: true,
      // permission: (permission && permission.Dashboard !== undefined ? permission && permission.Dashboard.includes("dashboard") : false),
    },
  },
  //Master's routes & permissions.
  {
    path: "master/user",
    component: AsyncUserMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("user")
          : false,
    },
  },

  // {
  // 	path: 'Attendance/ShiftMaster',
  // 	component: AsyncFormInformationComponent,
  // 	meta: { permission: false },
  // },
  {
    path: "master/designation",
    component: AsyncDesignationMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("designation_master")
          : false,
    },
  },
  {
    path: "master/department",
    component: AsyncDepartmentMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("department_master")
          : false,
    },
  },
  {
    path: "master/city",
    component: AsyncCityMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("city_master")
          : false,
    },
  },
  {
    path: "master/suburb",
    component: AsyncSuburbMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("suburb_master")
          : false,
    },
  },
  {
    path: "master/branch",
    component: AsyncBranchMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("branch_master")
          : false,
    },
  },
  {
    path: "master/leadsource",
    component: AsyncLeadSourceMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("lead_source_master")
          : false,
    },
  },
  {
    path: "master/model",
    component: AsyncModelMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("model_master")
          : false,
    },
  },
  {
    path: "master/colour",
    component: AsyncColourMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("color_master")
          : false,
    },
  },
  {
    path: "master/product",
    component: AsyncProductMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("product_master")
          : false,
    },
  },
  {
    path: "master/remark",
    component: AsyncRemarkMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("remark_master")
          : false,
    },
  },
  {
    path: "master/whatsappdraft",
    component: AsyncWaDraftMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("wa_draft_master")
          : false,
    },
  },
  {
    path: "master/templatetrigger",
    component: AsyncTemplateTriggerMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("template_trigger_master")
          : false,
    },
  },
  {
    path: "master/insurance",
    component: AsyncInsuranceMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("insurance_master")
          : false,
    },
  },
  {
    path: "master/insurancecompany",
    component: AsyncInsuranceCompanyMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("insurance_company_master")
          : false,
    },
  },
  {
    path: "master/whatsapp-draft-category",
    component: AsyncWaDraftCategoryMasterComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("wa_draft_category_master")
          : false,
    },
  },
  //Leads's routes & permissions.
  {
    path: "leads/leadsform",
    component: AsyncLeadsFormMasterComponent,
    meta: {
      // permission: true
      permission:
        permission && permission.Leads !== undefined
          ? permission && permission.Leads.includes("leads_form")
          : false,
    },
  },
  {
    path: "leads/leads-import",
    component: AsyncLeadsImportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.Leads !== undefined
          ? permission && permission.Leads.includes("leads_import")
          : false,
    },
  },
  // {
  //   path: "leads/customers-import",
  //   component: AsyncCustomersImportComponent,
  //   meta: {
  //     permission: true,
  //     // permission:
  //     //   permission && permission.Leads !== undefined
  //     //     ? permission && permission.Leads.includes("customers_import")
  //     //     : false,
  //   },
  // },

  //    {
  // 		path: 'sample-form',
  //       component:AsyncSampleFormComponent
  // 	}

  // report routes & permissions.
  {
    path: "crm-reports/leads-report",
    component: AsyncLeadsReportsComponent,
    meta: {
      permission: true,
      // permission: (permission && permission.Leads !== undefined ? permission && permission.Leads.includes("leads_report") : false),
    },
  },
  {
    path: "crm-reports/call-summary-report",
    component: AsyncCallingPerformanceReportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.CRM_Reports !== undefined
          ? permission && permission.CRM_Reports.includes("call_summary_report")
          : false,
    },
  },
  {
    path: "crm-reports/calling-history-report",
    component: AsyncCallingHistoryReportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.CRM_Reports !== undefined
          ? permission && permission.CRM_Reports.includes("calling_history_report")
          : false,
    },
  },

  {
    path: "serviceReports/customer-service-report",
    component: AsyncCustomerServiceReportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.serviceReports !== undefined
          ? permission &&
          permission.serviceReports.includes("customer_service_report")
          : false,
    },
  },
  {
    path: "serviceReports/service-summary-report",
    component: AsyncServiceSummaryReportComponent,
    meta: {
      // permission: true,
      permission:
        permission && permission.serviceReports !== undefined
          ? permission &&
          permission.serviceReports.includes("service_summary_report")
          : false,
    },
  },

  // Attendance

  {
    path: "attendance/shift",
    component: AsyncShiftMasterComponent,
    meta: {
      //permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("shift")
        : false,
    },
  },

  {
    path: "attendance/weekly-off",
    component: AsyncWeeklyOffMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("weekly_off")
        : false,
    },
  },
  {
    path: "attendance/holiday",
    component: AsyncHolidayMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("holiday")
        : false,
    },
  },

  {
    path: "attendance/attendance-report",
    component: AsyncAttendanceReportMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("daily_report")
        : false,
    },
  },
  {
    path: "attendance/monthly-attendance-report",
    component: AsyncMonthylyAttendanceReportMasterComponent,
    meta: {
      //permission: true,
      permission:
        permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("monthly_report")
          : false,
    },
  },

  {
    path: "crm-reports/test-ride-report",
    component: AsyncTestRideReportsComponent,
    meta: {
      //permission: true,
      permission: permission && permission.CRM_Reports !== undefined
        ? permission && permission.CRM_Reports.includes("test_ride_report")
        : false,
    },
  },
  {
    path: "crm-reports/user-call-log-report",
    component: AsyncUserCallLogReportComponent,
    meta: {
      permission: permission && permission.CRM_Reports !== undefined
        ? permission && permission.CRM_Reports.includes("user_call_log_report")
        : false,
    }
  },
  {
    path: "master/leadtype",
    component: AsyncLeadTypeMasterComponent,
    meta: {
      permission: permission && permission.Master !== undefined
        ? permission && permission.Master.includes("lead_type")
        : false,
    },
  },
  //----------------setting -------------permission------------//
  // {
  //   path: "setting/setting",
  //   component: AsyncSettingMasterComponent,
  //   meta: {
  //     // permission: true
  //     permission:
  //       permission && permission.Setting !== undefined
  //         ? permission && permission.Setting.includes("setting_master")
  //         : false,
  //   },
  // },
  {
    path: "hr-setting",
    component: AsyncHrSettingMasterComponent,
    meta: {
      permission: true
      // permission:
      //   permission && permission.Setting !== undefined
      //     ? permission && permission.Setting.includes("hr_setting")
      //     : false,
    },
  },

  {
    path: "attendance/weekly-off-roster",
    component: AsyncWeekOffRosterMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("weekly_off_roster")
        : false,
    },
  },

  {
    path: "attendance/attendance-summary-report",
    component: AsyncAttendanceSummaryReportComponent,
    meta: {
      //permission: true,
      permission:
        permission && permission.Attendance !== undefined
          ? permission && permission.Attendance.includes("summery_report")
          : false,
    },
  },

  {
    path: "attendance/leave-approval",
    component: AsyncLeaveApprovalMasterComponent,
    meta: {
      // permission: true,
      permission: permission && permission.Attendance !== undefined
        ? permission && permission.Attendance.includes("leave_approval")
        : false,
    },
  },

]

export default routes
