const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allActiveColor: [],
  }
  
  const colorReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_COLOR':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }

        case 'GET_DATA_ALL_ACITVE_COLOR':
          return {
            ...state,
            allActiveColor: action.data,
          }

      case 'CREATE_COLOR':
        return { ...state }
      case 'UPDATE_DATA_COLOR':
        return { ...state }
      case 'DELETE_COLOR':
        return { ...state }
      
      default:
        return { ...state }
    }
  }
  export default colorReducer
  