/**
 * Code splitting Components
 * AsyncComponents
 */
import React from "react";
import Loadable from "react-loadable";
import { HulkPageLoader } from "components/GlobalComponents";

//  Dashboard 1
const AsyncDashboardComponent = Loadable({
  loader: () => import("views/Dashboard"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Masters
const AsyncUserMasterComponent = Loadable({
  loader: () => import("views/Master/UserMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncFormInformationComponent = Loadable({
  loader: () => import("views/Master/FormInformation/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDesignationMasterComponent = Loadable({
  loader: () => import("views/Master/DesignationMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncDepartmentMasterComponent = Loadable({
  loader: () => import("views/Master/DepartmentMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCityMasterComponent = Loadable({
  loader: () => import("views/Master/CityMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncSuburbMasterComponent = Loadable({
  loader: () => import("views/Master/SuburbMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncBranchMasterComponent = Loadable({
  loader: () => import("views/Master/BranchMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncModelMasterComponent = Loadable({
  loader: () => import("views/Master/ModelMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncColourMasterComponent = Loadable({
  loader: () => import("views/Master/ColourMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncProductMasterComponent = Loadable({
  loader: () => import("views/Master/ProductMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncRemarkMasterComponent = Loadable({
  loader: () => import("views/Master/RemarkMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncWaDraftMasterComponent = Loadable({
  loader: () => import("views/Master/WaDraftMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncTemplateTriggerMasterComponent = Loadable({
  loader: () => import("views/Master/TemplateTriggerMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncInsuranceMasterComponent = Loadable({
  loader: () => import("views/Master/InsuranceMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncInsuranceCompanyMasterComponent = Loadable({
  loader: () => import("views/Master/InsuranceCompanyMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// const AsyncSettingMasterComponent = Loadable({
//   loader: () => import("views/Master/SettingMaster"),
//   loading: () => <HulkPageLoader />,
//   delay: 3000,
// });

const AsyncWaDraftCategoryMasterComponent = Loadable({
  loader: () => import("views/Master/WaDraftCategoryMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//Leads
const AsyncLeadSourceMasterComponent = Loadable({
  loader: () => import("views/Master/LeadSourceMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncLeadsFormMasterComponent = Loadable({
  loader: () => import("views/Master/LeadsFormMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncLeadsImportComponent = Loadable({
  loader: () => import("views/Master/LeadsImport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// const AsyncCustomersImportComponent = Loadable({
//   loader: () => import("views/Master/CustomersImport/"),
//   loading: () => <HulkPageLoader />,
//   delay: 3000,
// });

// Error Page 404
const AsyncErrorPage404Component = Loadable({
  loader: () => import("views/Error/404"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
  loader: () => import("views/Error/403"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
  loader: () => import("views/Error/500"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
  loader: () => import("views/SampleForm"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncLeadsReportsComponent = Loadable({
  loader: () => import("views/Reports/LeadsReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCallingPerformanceReportComponent = Loadable({
  loader: () => import("views/Reports/CallingPerformanceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncCallingHistoryReportComponent = Loadable({
  loader: () => import("views/Reports/CallingHistoryReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// Service module report
const AsyncCustomerServiceReportComponent = Loadable({
  loader: () => import("views/serviceReports/CustomerServiceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

const AsyncServiceSummaryReportComponent = Loadable({
  loader: () => import("views/serviceReports/ServiceSummaryReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

// ------------------Shift Master--------------------

const AsyncShiftMasterComponent = Loadable({
  loader: () => import("views/Attendance/ShiftMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//------------------weekly off Master-----------------
const AsyncWeeklyOffMasterComponent = Loadable({
  loader: () => import("views/Attendance/WeeklyOffMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//------------------Holiday Master-----------------
const AsyncHolidayMasterComponent = Loadable({
  loader: () => import("views/Attendance/HoliydayMaster"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//-------------------Attendance Report ------------
const AsyncAttendanceReportMasterComponent = Loadable({
  loader: () => import("views/Attendance/AttendanceReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//-------------------Monthly Attendance Report ------------

const AsyncMonthylyAttendanceReportMasterComponent = Loadable({
  loader: () => import("views/Attendance/MonthlyAttendanceReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
});

//-----------------Test Ride Report-----------------------//
const AsyncTestRideReportsComponent = Loadable({
  loader: () => import("views/Reports/TestRideReports/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})
//-------------User Call log report---------------------//
const AsyncUserCallLogReportComponent = Loadable({
  loader: () => import("views/Reports/UserCallLogReport"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})



//---------------Lead Type Master-------------------------//
const AsyncLeadTypeMasterComponent = Loadable({
  loader: () => import("views/Master/LeadTypeMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//--------------- Attendance Summary Report--------------//
const AsyncAttendanceSummaryReportComponent = Loadable({
  loader: () => import("views/Attendance/AttendanceSummaryReport/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//---------------HR setting Master----------------------//
const AsyncHrSettingMasterComponent = Loadable({
  loader: () => import("views/Master/HrSettingMaster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//------------Week Off Roster---------------/
const AsyncWeekOffRosterMasterComponent = Loadable({
  loader: () => import("views/Attendance/WeeklyOffRoster/"),
  loading: () => <HulkPageLoader />,
  delay: 3000,
})

//------------Leave Approval------------------//
const AsyncLeaveApprovalMasterComponent = Loadable({
  loader: () => import("views/Attendance/LeaveApproval/"),
  loading: () => < HulkPageLoader />,
  dalay: 3000,
})

export {
  AsyncDashboardComponent,
  AsyncUserMasterComponent,
  AsyncErrorPage404Component,
  AsyncErrorPage500Component,
  AsyncErrorPage403Component,
  AsyncSampleFormComponent,
  AsyncFormInformationComponent,
  AsyncDesignationMasterComponent,
  AsyncDepartmentMasterComponent,
  AsyncCityMasterComponent,
  AsyncSuburbMasterComponent,
  AsyncBranchMasterComponent,
  AsyncModelMasterComponent,
  AsyncColourMasterComponent,
  AsyncProductMasterComponent,
  AsyncRemarkMasterComponent,
  AsyncWaDraftMasterComponent,
  AsyncLeadSourceMasterComponent,
  AsyncLeadsFormMasterComponent,
  AsyncInsuranceCompanyMasterComponent,
  AsyncInsuranceMasterComponent,
  // AsyncSettingMasterComponent,
  AsyncTemplateTriggerMasterComponent,
  AsyncLeadsReportsComponent,
  AsyncLeadsImportComponent,
  AsyncCallingPerformanceReportComponent,
  AsyncCallingHistoryReportComponent,
  AsyncWaDraftCategoryMasterComponent,
  AsyncCustomerServiceReportComponent,
  AsyncServiceSummaryReportComponent,
  // Attendance Module component
  AsyncShiftMasterComponent,
  AsyncWeeklyOffMasterComponent,
  AsyncHolidayMasterComponent,
  AsyncAttendanceReportMasterComponent,
  AsyncMonthylyAttendanceReportMasterComponent,
  AsyncTestRideReportsComponent,
  // AsyncCustomersImportComponent,
  AsyncUserCallLogReportComponent,
  AsyncLeadTypeMasterComponent,
  AsyncAttendanceSummaryReportComponent,
  AsyncHrSettingMasterComponent,
  AsyncWeekOffRosterMasterComponent,
  AsyncLeaveApprovalMasterComponent,

};
